import React from "react"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const Hexout: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Hexout" />
      <h2>Hexout (Breakout Clone)</h2>
      <a href="https://liammyles.github.io/hexout/">
        View on Github Pages
      </a> or{" "}
      <a href="https://github.com/LiamMyles/hexout">
        Checkout Hexagons v2 Github repo
      </a>
      <ResponsiveIFrame src="https://liammyles.github.io/hexout/" />
    </StandardLayout>
  )
}

export default Hexout
